<template>
    <b-row class="match-height">
      <b-col
lg="12"
> <b-card
      title="Confirm Purchase Request By Division Head"
    >
      <b-form
          @submit.prevent="submitConfirmPurchaseRequest"
>
        <b-row>
          <b-col cols="12">
            <b-button
            type="button"
            variant="primary"
            class="mr-1"
            @click="previous()"
            >
            <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
            />
            Back
            </b-button>
            <div class="devider" />
        <b-col cols="12">
          <b-form-group
            label="Number"
            label-for="purchase_request_number"
            label-cols-md="12"
          >
            <b-form-input
              id="purchase_request_number"
              v-model="purchaseRequestNumber"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Class"
            label-for="purchase_request_class"
            label-cols-md="12"
          >
            <b-form-input
              id="chosenClass"
              v-model="chosenClass"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Project"
            label-for="purchase_request_project"
            label-cols-md="12"
          >
            <b-form-input
              id="purchase_request_project"
              v-model="purchaseRequestProject"
              placeholder="Project"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Cost Center"
            label-for="purchase_request_cost_center"
            label-cols-md="12"
          >
            <b-form-input
              id="chosenBranch"
              v-model="chosenBranch"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Type"
            label-for="purchase_request_type"
            label-cols-md="12"
          >
            <b-form-input
              id="purchase_request_type"
              v-model="purchaseRequestType"
              readonly
            />
          </b-form-group>
            <b-form-group
              label="Deliver To"
              label-for="purchase_request_deliver_to"
              label-cols-md="12"
            >
              <b-form-input id="deliverTo" v-model="purchaseRequestDeliverTo" readonly />
            </b-form-group>
            <b-form-group
              label="Need For"
              label-for="purchase_request_need_for"
              label-cols-md="12"
            >
              <b-form-input id="purchase_request_need_for" v-model="purchaseRequestNeedFor" readonly />
            </b-form-group>
            <b-form-group
              label="Category"
              label-for="purchase_request_category"
              label-cols-md="12"
            >
              <b-form-input id="purchase_request_category" v-model="purchaseRequestCategory" readonly />
            </b-form-group>
          <b-form-group
            label="Notes"
            label-for="purchase_request_notes"
            label-cols-md="12"
          >
            <b-form-input
              id="purchase_request_notes"
              v-model="purchaseRequestNotes"
              placeholder="Notes"
              readonly
            />
          </b-form-group>
            <b-form-group
  label="Asset Category"
  label-for="purchase_request_asset_category"
  label-cols-md="12"
  v-if="purchaseRequestAssetCategory != ''"
>
  <b-form-input
    id="purchase_request_asset_category"
    v-model="purchaseRequestAssetCategory"
    placeholder="Notes"
  v-if="purchaseRequestAssetCategory != ''"
    readonly
  />
</b-form-group>
        </b-col>
          <b-row>
            <b-col
cols="6"
class="text-center font-weight-bold"
>
              Item name
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Unit name
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Item QTY Requested
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Item QTY Accepted
            </b-col>
            </b-row>
             <p />
          <b-row
v-for="(chosen) in chosens"
:key="chosen.value"
>
          <br>
            <b-col cols="6 text-center">
                <b-list-group>
                  <b-list-group-item href="#">
{{ chosen.label }}
</b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col cols="2 text-center">
                <b-list-group>
                  <b-list-group-item href="#">
{{ chosen.unit_name }}
</b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col cols="2 text-center">
            <b-form-input
              v-model="chosen.qty_requested"
              type="number"
              placeholder="Qty Requested"
              :max="chosen.qty_requested"
              readonly
            />
            </b-col>
            <b-col cols="2 text-center">
            <b-form-input
              v-model="chosen.qty_accepted"
              type="number"
              placeholder="Qty Accepted"
              :max="chosen.qty_accepted"
              readonly
            />
            </b-col>
            <b-col cols="12">
              <br>
            </b-col>
            </b-row>
          <b-col offset-md="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="submitButtonDisabled"
            >
              Confirm
            </b-button>
          </b-col>
          </b-col>
          <!-- submit and reset -->
        </b-row>
      </b-form>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'

  import { ref } from '@vue/composition-api'

  const VersionCheck = ''
  const items = []
  const chosenItem = ref(3)
  const chosens = []
  const purchaseRequestIsConfirmed = ''
  const purchaseRequestClass = ''
  const purchaseRequestProject = ''
  const purchaseRequestCostCenter = ''
  const purchaseRequestQtyRequested = 0
  const purchaseRequestStatus = ''
  const purchaseRequestNotes = ''
  const purchaseRequestType = ''
  const purchaseRequestNumber = ''
const purchaseRequestDeliverTo = ""
const purchaseRequestNeedFor = ""
const purchaseRequestCategory = ""
const purchaseRequestAssetCategory = ""
const submitButtonDisabled = false
  export default {
    components: {
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        purchaseRequestAssetCategory,
        submitButtonDisabled,
        purchaseRequestNumber,
        purchaseRequestType,
    VersionCheck,
    items,
    chosenItem,
    chosens,
    purchaseRequestIsConfirmed,
    purchaseRequestClass,
    purchaseRequestProject,
    purchaseRequestCostCenter,
    purchaseRequestQtyRequested,
    purchaseRequestStatus,
    purchaseRequestNotes,
      purchaseRequestDeliverTo,
      purchaseRequestNeedFor,
      purchaseRequestCategory,
      }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
    getDetail() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      const id = localStorage.getItem('purchaseRequestDetailsId')
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS_DETAILS}${id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
          axios
            .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS_SINGLE}${id}`, { headers })
            .then(response2 => {
              if (response2.data.success === true) {
                this.purchaseRequestNumber = response2.data.data.purchase_request_number
                this.purchaseRequestType = response2.data.data.purchase_request_is_center === true ? 'Headoffice' : 'Cabang'
                this.chosenClass = response2.data.data.purchase_request_class
                this.purchaseRequestProject = response2.data.data.purchase_request_project
                this.chosenBranch = `${response2.data.data.cost_center.branch_city} (${response2.data.data.cost_center.branch_code})`
                this.purchaseRequestNotes = response2.data.data.purchase_request_notes
                this.purchaseRequestIsConfirmed = response2.data.data.purchase_request_is_confirmed === true ? true : false
                  this.purchaseRequestNeedFor = response2.data.data.purchase_request_need_for
                  this.purchaseRequestCategory = response2.data.data.purchase_request_category
                  this.purchaseRequestDeliverTo = response2.data.data.purchase_request_deliver_to
                  this.purchaseRequestAssetCategory = response2.data.data.purchase_request_asset_category !== null && typeof response2.data.data.purchase_request_asset_category !== 'undefined' ? response2.data.data.purchase_request_asset_category : ''
              } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Get Data Failed',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
              }
              })
              const itemsJoin = []
              response.data.data.map(elem => {
                itemsJoin.push({
                  unit_name: `${elem.item.unit ? elem.item.unit.unit_name : '-'}`,label: `${elem.item.item_name}`, value: elem.purchase_request_detail_id, qty_requested: elem.purchase_request_detail_item_qty_requested, qty_accepted: elem.purchase_request_detail_item_qty_accepted,
                 })
              })
              this.chosens = itemsJoin
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Branches Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Data Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    submitConfirmPurchaseRequest() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        const id = localStorage.getItem('purchaseRequestDetailsId')
        const body = {
        time: moment(),
        }
    axios
      .post(`${process.env.VUE_APP_API_URL}transactions/purchase_requests/confirm/center/division_head/${id}`, body, { headers })
      .then(async response => {
        this.submitButtonDisabled = false
        if (response.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Purchase Request Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.replace({ name: 'apps-procurement-purchase-request-list' })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
                if (elem.value === val.value) {
                  same = true
                }
          })
          if (same === false) {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = {
                    unit_name: elem.unit_name, label: elem.label, value: elem.value, qty: 1,
                  }
                }
              })
            this.chosens.push(item)
          }
        } else {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = {
                    unit_name: elem.unit_name, label: elem.label, value: elem.value, qty: 1,
                  }
                }
            })
            this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.value !== val))
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
    previous() {
          this.$router.push({ name: 'apps-procurement-purchase-request-list' })
    },
    },
  }
  </script>
